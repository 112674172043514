import { CarrinhoService      } from './service/domain/carrinho.service';
import { Component, Renderer2 } from '@angular/core';
import { Storage              } from '@ionic/storage';
import { Router               } from '@angular/router';
import { AlertController, MenuController, Platform } from '@ionic/angular';
//import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { isPlatform           } from '@ionic/angular';
import { CarrinhoPage         } from './carrinho/carrinho.page';
import { register             } from 'swiper/element/bundle';
import { App                  } from '@capacitor/app';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],

})
export class AppComponent {
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  LOGADO      : boolean;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  NAO_LOGADO  : boolean = true;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  CLIENTE     : any;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types, @typescript-eslint/type-annotation-spacing
  timeLeft    : number = 5;
  interval;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  PEDIDO      : any;
  public appPages = [
    {
      title: 'Início',
      url: '/empresas/',
      icon: 'storefront'
    },
    //{
    //  title: 'Status',
   //   url: '/status',
  //    icon: 'bicycle'
  //  },



  ];
  private _storage: Storage | null = null;

  constructor(
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private storage         : Storage,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private router          : Router,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    private render          : Renderer2,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    public  menuCtrl        : MenuController,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
    public  alertController : AlertController,
    // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
    private SERVIDOR        : CarrinhoService,


  ) {

    this.init();
    if (!isPlatform('capacitor')) {
   //   GoogleAuth.initialize()
    }
  }
  async signOut() {
  //  await GoogleAuth.signOut();
  }
  exitApp(){
    window.close();
 }
  async logout() {
    const name = await this.storage.get('DADOS');

    // eslint-disable-next-line prefer-const
    for (let item of name) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.CLIENTE = item['CLIENTE'];
    }
    const PEDIDO = await this.storage.get('PEDIDO');
    // eslint-disable-next-line eqeqeq
    if (this.CLIENTE == '1') {
      if (PEDIDO > 0) {
        this.msg('Existe um pedido em aberto !!',PEDIDO);
        return;
      }
    }


    await this.storage.remove('DADOS');
    this.signOut();
    // eslint-disable-next-line @typescript-eslint/naming-convention, prefer-const
    let JsonLocal = {
      // eslint-disable-next-line quote-props
      'result': [{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CLIENTE: '1',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        RAZAO: 'Consumidor',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        SENHA: '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EMAIL: ''
      }]
    };

    await this.storage.clear;
    // eslint-disable-next-line @typescript-eslint/dot-notation
    await this.storage.set('DADOS', JsonLocal['result']);
    await this.storage.set('PEDIDO', 0);
    this.menuCtrl.close();
    this.router.navigate(['/login']);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async msg(msg,PEDIDO) {
    const alert = await this.alertController.create({
      header   : msg,
      cssClass : 'custom-alert',
      buttons  : [
        {
          text: 'Sair',
          cssClass: 'alert-button-cancel',
          handler: async (data) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.SERVIDOR.DELETEPEDIDO(PEDIDO).subscribe(Response => { });
            await this.storage.set('PEDIDO', 0);
            this.logout();
         //
          }
        },
        {
          text: 'Carrinho',
          cssClass: 'alert-button-confirm',
          handler: (data) => {
            this.menuCtrl.close();
            this.router.navigate(['/carrinho/']);
          }
        },
      ],
    });

    await alert.present();


  }

  close(){
    console.log('Botao Close');
    App.exitApp();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  async ngOnInit() {
    const name = await this.storage.get('DADOS');


    if (name == undefined) {
      // eslint-disable-next-line @typescript-eslint/naming-convention, prefer-const
      let JsonLocal = {
        // eslint-disable-next-line quote-props
        'result': [{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          CLIENTE: '1',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          RAZAO: 'Consumidor',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          SENHA: '',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          EMAIL: ''
        }]
      };

      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.storage.set('DADOS', JsonLocal['result']);
      this.storage.set('PEDIDO', 0);
      this.storage.set('LOJA',0);

    }


    // eslint-disable-next-line prefer-const
    for (let item of name) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.CLIENTE = item['CLIENTE'];
    }
    if (this.CLIENTE !== null) {
      this.NAO_LOGADO = false;
      this.LOGADO     = true;
    } else {
      this.NAO_LOGADO = true;
      this.LOGADO = false;
    }
  }
  async ionViewWillEnter() {
    const name = await this.storage.get('DADOS');
    // eslint-disable-next-line prefer-const
    for (let item of name) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.CLIENTE = item['CLIENTE'];
    }

    if (this.CLIENTE !== null) {
      this.NAO_LOGADO = false;
      this.LOGADO     = true;
    } else {
      this.NAO_LOGADO = true;
      this.LOGADO     = false;
    }
  };
  async init() {
    const storage = await this.storage.create();
    // eslint-disable-next-line no-underscore-dangle
    this._storage = storage;
  }
  mudarThema() {
    this.render.setAttribute(document.body, 'color-theme', 'dark');
  }
  mudarThemalight() {
    this.render.setAttribute(document.body, 'color-theme', 'light');
  }
  async login() {
    this.CLIENTE = '';
    const name = await this.storage.get('DADOS');

    // eslint-disable-next-line prefer-const
    for (let item of name) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.CLIENTE = item['CLIENTE'];
    }
    if (name == null) {
      await this.storage.set('login',1);
      this.router.navigate(['/login/']);

    } else {

      // eslint-disable-next-line eqeqeq
      if(this.CLIENTE == 1 ){
        this.menuCtrl.close();
        await this.storage.set('login',1);
        this.router.navigate(['/login/']);
      }else
      {
        this.menuCtrl.close();
        this.router.navigate(['/perfil/']);
      }

    }
  }

}
