/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */

import { Injectable } from '@angular/core';
import { ProdutosService } from './service/domain/produtos.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})

export class ServidorService {
  private _storage: Storage | null = null;


  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  CLIENTE    : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  EMPRESALOG : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  baseUrl    : string;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  PASTA      : string;
  constructor() {
    this.EMPRESALOG = 1; //@@@
    this.ValidaBanco();
  }


  ValidaBanco() {


    // BASE TESTE
    if (this.EMPRESALOG === 316) {
      this.baseUrl = 'https://erosapp.com.br/api_debug/';  //BASE TESTE
      this.PASTA   = 'NAIR';
    }
    else if (this.EMPRESALOG === 1) {
      this.baseUrl = 'https://erosapp.com.br/api_teste'; //producao
      this.PASTA   = 'TESTE';
    }


  }




}
