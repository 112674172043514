/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServidorService } from 'src/app/servidor.service';



// eslint-disable-next-line @typescript-eslint/naming-convention
export interface message {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  STATUS  : string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  RETORNO : string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  PEDIDO  : string,

}
export interface MSG {
// eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  success: string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  msg    : string,

}
export interface ESPECIE {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  ESPECIE  : number,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  DESCRICAO: string,

}
export interface RESUMO {
// eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  SUB_TOTAL : number,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  TAXA      : number,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  OBS_TAXA  : string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  KM        : string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  msg       : string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  TOTAL     : number,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  endereco  : string
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  tipo      : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  RETIRAR   : string;

}
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface dados {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  ITEM         : string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  VALOR        : string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  QTD          : string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  TOTAL        : string,
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  NOME_PRODUTO : string,

}
export interface REFAZER {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  result: string,
}



export interface ProdutosRel {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  id        : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  nome_cat  : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  OBS_GRUPO : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  QTD_MINIMA: string;
  produtos: {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
    id       : string;
    // eslint-disable-next-line max-len
   // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
    nome     : string;
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
    grupo    : string;
    // eslint-disable-next-line max-len
   // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
    adiconais:{

    }[];
  }[];

}

export interface ITEM {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  ITEM          : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  VALOR         : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  QTD           : number;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  TOTAL         : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  NOME_PRODUTO  : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  Grupo_item    : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  composicao    : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  nome_grupo    : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  OBS           : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  QTD_MINIMA    : string;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style, @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  observacao    : string;

}
@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {
  private _storage: Storage | null = null;
  constructor(
    private http: HttpClient,
    private config: ServidorService,

  ) {

  }




  // eslint-disable-next-line @typescript-eslint/naming-convention, space-before-function-paren
  add_carrinho
    // eslint-disable-next-line @typescript-eslint/naming-convention
    (CLIENTE, QUANT, PROD, VAUNIT, PEDIDO, TOTAL, TIPO, OBS,EMPRESA) {
    return this.http.get<[message]>
      (

        // eslint-disable-next-line max-len
        this.config.baseUrl + '/add_carrinho?QUANTIDADE=' + QUANT + '&ID_PRODUTO=' + PROD + '&VALOR_UNI=' + VAUNIT + '&PEDIDO=' + PEDIDO + '&CLIENTE=' + CLIENTE + '&TOTAL=' + TOTAL + '&TIPO=' + TIPO + '&OBS=' + OBS+'&EMPRESA='+EMPRESA);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, max-len
  listar(CLIENTE, PEDIDO,EMPRESA) {
    return this.http.get<[dados]>(this.config.baseUrl + '/LISTAR?CLIENTE=' + CLIENTE + '&PEDIDO=' + PEDIDO+'&EMPRESA='+EMPRESA);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  listar_produto_rel(empresa) {

    return this.http.get<[ProdutosRel]>(this.config.baseUrl + '/LISTAR_produto?empresa='+empresa);

  }

  FINALIZAR(EMITIRNOTA,CLIENTE, E, especie, taxa, troco, OBS,GERAPIX,AGENDAMENTO,AGENDAMENTO_HORARIO,EMPRESA) {

    // eslint-disable-next-line max-len
    return this.http.get<[MSG]>(this.config.baseUrl + '/FINALIZAR?EMITIRNOTA='+EMITIRNOTA+'&CLIENTE=' + CLIENTE + '&ENDERECO=' + E + '&ESPECIE=' + especie + '&TAXA=' + taxa + '&troco=' + troco + '&MENSAGEMPEDIDO=' + OBS+'&GERAPIX='+GERAPIX+'&AGENDAMENTO='+AGENDAMENTO+'&AGENDAMENTO_HORARIO='+AGENDAMENTO_HORARIO+'&EMPRESA='+EMPRESA);
  }

  Query(sql) {

    // eslint-disable-next-line max-len
    return this.http.get<[MSG]>(this.config.baseUrl + '/QUERY?sql='+sql);
  }
  selecionar_especie(CLIENTE, E,EMPRESA) {

    return this.http.get<[MSG]>(this.config.baseUrl + '/SELECIONAR_ESPECIE?CLIENTE=' + CLIENTE + '&ESPECIE=' + E+'&EMPRESA='+EMPRESA);
  }

  REMOVER(ITEM, CLIENTE,PEDIDO,ID_CONBO,EMPRESA) {
    // eslint-disable-next-line max-len
    return this.http.get<[MSG]>(this.config.baseUrl + '/REMOVER?CLIENTE=' + CLIENTE + '&ITEM=' + ITEM+'&PEDIDO='+PEDIDO+'&ID_CONBO='+ID_CONBO+'&EMPRESA='+EMPRESA);
  }

  ESPECIE(EMPRESA) {
    return this.http.get<[ESPECIE]>(this.config.baseUrl + '/ESPECIE?EMPRESA='+EMPRESA);
  }

  RESUMO(CLIENTE, ENDERECO, PEDIDO,EMPRESA: any) {
    // eslint-disable-next-line max-len
    return this.http.get<[RESUMO]>(this.config.baseUrl + '/RESUMO?CLIENTE=' + CLIENTE + '&ENDERECO=' + ENDERECO + '&PEDIDO=' + PEDIDO+'&EMPRESA='+EMPRESA);
  }
  REFAZER(CLIENTE, PEDIDO) {
    return this.http.get<[REFAZER]>(this.config.baseUrl + '/REFAZER_PEDIDO?CLIENTE=' + CLIENTE + '&PEDIDO=' + PEDIDO);
  }
  trocarTipo(CLIENTE, TIPO ,EMPRESA) {
    return this.http.get<[REFAZER]>(this.config.baseUrl + '/ALTERAR_TIPO?CLIENTE=' + CLIENTE + '&TIPO=' + TIPO+'&EMPRESA='+EMPRESA);
  }
  LISTAR_ITEM(CLIENTE, ITEM,PEDIDO) {
    return this.http.get<[ITEM]>(this.config.baseUrl + '/ITEM?CLIENTE=' + CLIENTE + '&ITEM=' + ITEM + '&PEDIDO='+PEDIDO);
  }
  DELETEPEDIDO(PEDIDO) {
    return this.http.get<[ITEM]>(this.config.baseUrl + '/DELETEPEDIDO?PEDIDO=' + PEDIDO);
  }


  VALIDAKM(CLIENTE, ENDERECO,EMPRESA) {
    return this.http.get<[]>(this.config.baseUrl + '/VALIDA_KM?CLIENTE=' + CLIENTE + '&ENDERECO=' + ENDERECO+'&EMPRESA='+EMPRESA);
  }


  GetAddress(cep) {


    return this.http.get('http://www.viacep.com.br/ws/' + cep + '/json/');
  }





}
