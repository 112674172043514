



import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, ToastController } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ServidorService } from './servidor.service';
import { DatabaseService } from './service/database.service';
import { Storage } from '@ionic/storage-angular';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';


import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { CurrencyMaskConfig,CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { TextMaskModule } from 'angular2-text-mask';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { EnderecosPage } from './enderecos/enderecos.page';
import { ReactiveFormsModule } from '@angular/forms';


import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';


export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  decimal: ',',
  precision:2,
  prefix:'',
  suffix:'',
  thousands:'.'
};

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CurrencyMaskModule,
    ReactiveFormsModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,

      registrationStrategy: 'registerWhenStable:30000'
    }),


  ],
  providers: [
    ServidorService,
    DatabaseService,
    Storage,
    ToastController,
    Geolocation,
    Vibration,
    AppVersion,
    InAppBrowser,
    TextMaskModule,
    Network,
    EnderecosPage,
    GooglePlus,

    {
     provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },

    {provide: CURRENCY_MASK_CONFIG,useValue: customCurrencyMaskConfig}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {




}


