/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/dot-notation */

import { Component, Input, OnInit        } from '@angular/core';
import { ActivatedRoute, Router          } from '@angular/router';
import { ENDERECO, UsuarioService        } from '../service/domain/usuario.service';
import { Storage                         } from '@ionic/storage-angular';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { CarrinhoService                 } from '../service/domain/carrinho.service';
import { Vibration                       } from '@awesome-cordova-plugins/vibration/ngx';
import { ConfirmacaoPage                 } from '../confirmacao/confirmacao.page';
import { BuscacepPage                    } from '../buscacep/buscacep.page';




@Component({
  selector: 'app-enderecos',
  templateUrl: './enderecos.page.html',
  styleUrls: ['./enderecos.page.scss'],
})
export class EnderecosPage implements OnInit {
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  cliente     : string;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  dadosLogin  : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
  cpf         : string;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  ENDERECO    : ENDERECO[];
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  id_cliente  : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  T_ENDERECO  : any;
   // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/naming-convention
  busca_cep   : boolean;
  private _storage: Storage | null = null;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  @Input() PGcomfirmacao      : ConfirmacaoPage;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, @typescript-eslint/type-annotation-spacing
  LOJA :any;

  // eslint-disable-next-line space-before-function-paren
  constructor
    (
      // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
      private router          : Router,
       // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
      private actRouter       : ActivatedRoute,
       // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
      private service         : UsuarioService,
       // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
      private storage         : Storage,
       // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
      public  alertController : AlertController,
       // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
      private loadingCtrl     : LoadingController,
       // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
      private provider        : CarrinhoService,
       // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
      public  toast           : ToastController,
       // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
      private vibration       : Vibration,
       // eslint-disable-next-line @typescript-eslint/type-annotation-spacing
     private modalController  : ModalController,

    ) {
    this.init();


  }

  async showLoading() {
    const loading = await this.loadingCtrl.create({
      message   : 'Carregando...',
      duration  : 700,
      spinner   : 'circles',
    });

    loading.present();
  }

  // listar resumo
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-shadow
  Valida_Endereco(ENDERECO) {
    this.showLoading();
    this.provider.VALIDAKM(this.id_cliente, ENDERECO,this.PGcomfirmacao.LOJA.EMPRESA).subscribe(async data => {

      // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-const
      let msg = data['MSG'];
      // eslint-disable-next-line @typescript-eslint/dot-notation
      if (data['success'] === true) {
        this.MSG(msg);
        this.vibration.vibrate([200]);
        return;
      } else {
        await this.storage.set('ED_PEDIDO', ENDERECO);

        const T_ENDERECO = await this.storage.get('T_ENDERECO');
        const P_ENDERECO = await this.storage.get('T_ENDERECO');


        this.T_ENDERECO = T_ENDERECO;
        await this.storage.remove('T_ENDERECO');
        // eslint-disable-next-line eqeqeq
        if (this.T_ENDERECO == 1) {
          this.modalController.dismiss();
        }
        else {
          this.modalController.dismiss();
        }


        await this.storage.remove('P_ENDERECO');
        // eslint-disable-next-line eqeqeq
        if (P_ENDERECO == 1) {
          await this.storage.set('endereco_pemitido', 'ok');
          this.PGcomfirmacao.lista_resumo();
          this.modalController.dismiss();
        }
        else {
          this.modalController.dismiss();
        }
      }

    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async MSG(msg) {
    const toast = await this.toast.create({
      message   : msg,
      duration  : 1500,
      color     : 'warning',
      position  : 'top'
    });
    toast.present();
  }

  async init() {
    const storage = await this.storage.create();
    // eslint-disable-next-line no-underscore-dangle
    this._storage = storage;
  }

  //DELETA ENDERECO
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DELETA_ENDERECO(ID, CLIENTE) {

    this.service.getdeleteENDERECO(ID, CLIENTE).subscribe(async data => {
      this.ENDERECO = [];
      this.showLoading();
      this.ListarEndereços(CLIENTE);
    });

  }

  async ngOnInit() {

    const loja           = await this.storage.get('LOJA');
    this.LOJA            = loja;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public async ListarEndereços(CLIENTE) {
    await this.storage.remove('ENDERECOS');

    this.service.getENDERECO(CLIENTE,this.LOJA.EMPRESA).subscribe(async data => {

      this.ENDERECO = [];
      for (let item of data['result']) {
        this.ENDERECO.push(item);
        await this.storage.remove('ENDERECOS');
        await this.storage.set('ENDERECOS', data['result']);
      }
      if (data == undefined) {
        this.busca_cep = true;
      }
    });

  }


  public async ionViewWillEnter() {
    this.showLoading();

    const ENDERECOS = await this.storage.get('ENDERECOS');
    this.ENDERECO = ENDERECOS;


    const name = await this.storage.get('DADOS');

    for (let item of name) {
      this.id_cliente = item['CLIENTE'];
    }


    //const ENDERECOS = await this.storage.get('ENDERECOS');
    //this.ENDERECO   = ENDERECOS;

    this.ListarEndereços(this.id_cliente);

  }
  voltar() {
    this.modalController.dismiss();
  }
  cadastro() {
    this.OpenModalBusca();
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  async continuar(ID) {
    this.Valida_Endereco(ID);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  async OpenModalBusca(){

    const modal = await this.modalController.create({
      component      : BuscacepPage,
      componentProps : {
        // eslint-disable-next-line object-shorthand, @typescript-eslint/naming-convention
        Pgenderecos  : this

      }
    });

    return await modal.present();
  }




}
