/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/type-annotation-spacing */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpClient                       } from '@angular/common/http';
import { Component, Input, OnInit                } from '@angular/core';
import { NavigationExtras, Router         } from '@angular/router';
import { CarrinhoService                  } from '../service/domain/carrinho.service';
import { Storage                          } from '@ionic/storage-angular';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { DatabaseService                  } from '../service/database.service';
import { Console                          } from 'console';
import { Geolocation                      } from '@awesome-cordova-plugins/geolocation/ngx';
import { EnderecoCadastroPage             } from '../endereco-cadastro/endereco-cadastro.page';
import { EnderecosPage                    } from '../enderecos/enderecos.page';


@Component({
  selector: 'app-buscacep',
  templateUrl: './buscacep.page.html',
  styleUrls: ['./buscacep.page.scss'],
})
export class BuscacepPage implements OnInit {


  private _storage: Storage | null = null;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  address                 :string="";
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  cep1                    :string="";
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  errorMessage            ;
// eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  endereco                : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  data                    : any;
// eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  logradouro              : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  complemento             : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  bairro                  : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  localidade              : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  uf                      : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  ibge                    : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  gia                     : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  ddd                     : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  siafi                   : any;
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes
  referencia              : any;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes, @typescript-eslint/naming-convention
  CLIENTE                 : any;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes, @typescript-eslint/naming-convention
  LISTA                   : any = [];
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes, @typescript-eslint/naming-convention
  EMPRESA_CIDADE          : any;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes, @typescript-eslint/naming-convention
  EMPRESA_UF              : any;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes, @typescript-eslint/naming-convention
  BUSCA                   : any;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/type-annotation-spacing, @typescript-eslint/member-ordering, @typescript-eslint/quotes, @typescript-eslint/naming-convention
  id_cliente              : any;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() Pgenderecos    : EnderecosPage;

  constructor(
    private router             : Router,
    private rest               : CarrinhoService,
    private http               : HttpClient,
    private storage            : Storage,
    public  toast              : ToastController,
    public  alertController    : AlertController,
    private base               : DatabaseService,
    private geolocation        : Geolocation,
    private modalController    : ModalController,
  )
  {
    this.init();
   }

  ngOnInit() {

    this.cep1  = '';
  }
  async init() {

    const storage = await this.storage.create();
    // eslint-disable-next-line no-underscore-dangle
    this._storage = storage;



  }

  async msg(msg,tempo) {
    const toast = await this.toast.create({
      message  : msg,
      duration : tempo,
      position : 'bottom',
      color    : 'tertiary'
    });

    toast.present();
  }

  voltar()
   {
    this.router.navigate(['/enderecos/']);
   }

   // eslint-disable-next-line @typescript-eslint/naming-convention
   Localizacao(){
    this.msg('Aguarde.....',2800);
    this.geolocation.getCurrentPosition().then(async (resp) => {
        this.endereco = this.getAddressFromLatLng(resp.coords.latitude,resp.coords.longitude);
        await this.storage.remove('DADOS_CEP');
        await this.storage.set('DADOS_CEP', this.endereco);
       this.OpenModalCadastro();

     }).catch((error) => {
      alert(error);
     });

   }

   async  getAddressFromLatLng(lat: number, lng: number): Promise<string> {
    // eslint-disable-next-line max-len
    const response    = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCKCINfyXR_am9kR492FRWixhFKop_2CI8`);
    const data        = await response.json();

     const { results } = data;

    if (results.length === 0) {
      throw new Error('No results found');
    }

    const address = results[0].formatted_address;
    return address;
  }

   separateAddress(address: string): string[] {
    // eslint-disable-next-line @typescript-eslint/quotes
    return address.split(", ");
  }

 async ionViewWillEnter()

 {
  await this.storage.remove('DADOS_CEP');


  this.CLIENTE = [];
  const name   = await this.storage.get('DADOS');
  this.CLIENTE = name;

  // eslint-disable-next-line prefer-const
  for (let item of name) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.id_cliente = item['CLIENTE'];}

  this.lojista();




 }

 async lojista()
 {
  const loja         = await this.storage.get('LOJA');

  // eslint-disable-next-line @typescript-eslint/dot-notation, no-underscore-dangle
  this.EMPRESA_UF     = loja.UF;
  // eslint-disable-next-line @typescript-eslint/dot-notation, no-underscore-dangle
  this.EMPRESA_CIDADE = loja.CIDADE;
  /*this.base.getEmpresa().subscribe(async Response => {

      await this.storage.set('DADOS_EMPRESA', Response);

            // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-const
            for(let item of Response['result']){
             this.LISTA.push(item);
              // eslint-disable-next-line @typescript-eslint/dot-notation
              this.EMPRESA_UF     = Response['UF_'];
              // eslint-disable-next-line @typescript-eslint/dot-notation
              this.EMPRESA_CIDADE = Response['CIDADE_'];

            }


     });*/
 }



 async GetAddress(id_cliente){

  // eslint-disable-next-line eqeqeq
  if(this.cep1     == ''){this.enderecoNaoEncotrado('Preencha o cep ou a rua ');}
  else{// VERIFICAR SE É STRING OU NÚMERO

  // eslint-disable-next-line no-var, radix
  var num1         = parseInt(this.cep1);

  if(num1 % 1 === 0)
  {
    this.BUSCA = this.cep1;
  }
  else
  {
    this.BUSCA =  this.EMPRESA_UF + '/' + this.EMPRESA_CIDADE+ '/' + this.cep1;
  }

   await this.storage.remove('DADOS_CEP'); //LIMPA O CACHE

   // eslint-disable-next-line @typescript-eslint/quotes
   this.http.get("https://viacep.com.br/ws/" +this.BUSCA+"/json").subscribe(async Response => {
    //CONSULTA VIACEP

  // eslint-disable-next-line no-var
  var data = await this.storage.set('DADOS_CEP', Response);


     // eslint-disable-next-line eqeqeq
     if(data['erro '] == 'true' )
     {

         this.enderecoNaoEncotrado('cep inválido!!');
     }
     else
     {
       // eslint-disable-next-line eqeqeq
       if(data == undefined)
       {

           this.enderecoNaoEncotrado('Não encontramos o seu cep !!');
       }
       else
       {


        // eslint-disable-next-line eqeqeq
        if(data == '')
        {
          this.enderecoNaoEncotrado('Endereço não existe');
        }
        else

        // eslint-disable-next-line curly
        this.OpenModalCadastro();
       }

     }


    });

  }


}

async OpenModalCadastro(){
  this.modalController.dismiss();
  const modal = await this.modalController.create({
    component      : EnderecoCadastroPage,
    cssClass       : 'ion-modal',
    componentProps : {
      PGbuscaCep   : this,
      Pgenderecos  : this.Pgenderecos
    }
  });
  await modal.present();
}

async enderecoNaoEncotrado(msg){



  const alert = await this.alertController.create({
    header: msg,
    backdropDismiss: false,




    buttons: [
    {
      text: 'ok',
      handler: (data) => {


        }
      }
    ]
  });

  await alert.present();

}


home() {
 this.modalController.dismiss();
}

naosei_meu_cep(id_cliente)
{
  this.router.navigate(['/endereco-cadastro/'+id_cliente]);
}


}
